$c-primary: #006593;
$c-secandary: #003057;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Arial', sans-serif;
$font-family-heading: 'Futura', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Futura';
        src: url('/assets-mobile/fonts/Futura.ttf');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$btn-text: white;
@if variable-exists(btn-text-overrided) {
  $btn-text: $btn-text-overrided;
}

.btn {
  border: 1px solid var(--primary);
  background: var(--primary);
  font-family: var(--base-font-family);
  display: inline-block;
  color: $btn-text;
  height: 100%;
  font-size: 1rem; //16
  border-radius: 0.25rem; //4
  padding: 0.125rem 1rem 0.25rem; //2 16 4
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  font-weight: 500;
  &.rounded {
    border-radius: 0.625rem; //10
  }

  &.large {
    height: 3.75rem; //60
    padding-top: 0px;
  }

  &.right {
    text-align: right;
  }

  &.full {
    width: 100%;
  }

  &.shadow {
    box-shadow: 0px 5px 20.16px 3.84px lighten(#000, 87%);
  }

  &.secandary {
    background: var(--secondary);
    border-color: var(--secondary);
  }

  &.transparent {
    background: transparent;
    border-color: transparent;
    color: black;
    width: auto !important;
  }

  &.link {
    background: transparent;
    border-color: transparent;
    color: black;
    height: auto;
    width: auto !important;
    text-align: inherit;
    font-weight: normal;
    padding: 0;
  }

  &.outline {
    background: none;
    color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
  }

  &.outline.secandary {
    color: var(--secondary);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:active {
    box-shadow: none;
    opacity: 0.9;
  }

  &[disabled] {
    opacity: 0.3;
  }

  .img-back {
    position: relative;
    width: auto;
    height: 2rem; //32
    margin-top: -0.1875rem; //3
    margin-left: -1rem;
  }

  .spinner {
    margin-left: 0.5rem; //8
    img {
      animation: rotate 0.5s linear infinite;
      width: 1.5rem; //24
    }
    svg {
      animation: rotate 0.5s linear infinite;
      width: 1.5rem; //24
    }
    .svg {
      fill: black;
    }
  }
}
