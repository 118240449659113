$c-primary: #006593;
$c-secandary: #003057;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Arial', sans-serif;
$font-family-heading: 'Futura', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Futura';
        src: url('/assets-mobile/fonts/Futura.ttf');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.link {
  font-size: inherit;
  text-decoration: underline;
}

.input {
  display: flex;
  margin-bottom: 1rem;

  .code {
    padding-right: 10px;
  }

  .number {
    input {
      height: auto;
    }
  }
}
