$c-primary: #006593;
$c-secandary: #003057;
$dots: false;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Arial', sans-serif;
$font-family-heading: 'Futura', sans-serif;

      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Rubik';
        src: url('/assets-mobile/fonts/Rubik-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Futura';
        src: url('/assets-mobile/fonts/Futura.ttf');
        font-weight: normal;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'SourceSansPro';
        src: url('/assets-mobile/fonts/SourceSansPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .description {
    text-align: left;
  }

  .document_list {
    margin-top: 1.5rem; //24
    margin-bottom: 1.5rem; //24

    .document_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      height: 3.3125rem; //53
      margin-bottom: 0.875rem; //14
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.625rem; //10
      cursor: pointer;
      &.checked {
        background: #ececec;
        border: 1px solid #000000;
      }

      input {
        display: none;
      }

      .check_mark_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 3.3125rem; //53
        height: 3.3125rem; //53

        &.checked {
          margin-top: -0.25rem;
        }
      }

      .document_title {
        flex: 1;
      }

      .card_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 4.5rem; //72
        height: 3.3125rem; //53

        .img_card {
          height: auto;
          &.passport {
            width: 1.625rem; //26
          }
          &.card {
            width: 3rem; //16
          }
        }
      }
    }
  }

  .progress_bar {
    position: fixed;
    width: 0%;
    height: 0.4375rem; //7
    background: black;
    bottom: 4.8125rem;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;
  }
}
